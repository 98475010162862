import getConfig from 'next/config'

import type { BrConfigurationContextOptions } from '@grandvisionhq/bloomreach'
import { withBloomreach } from '@grandvisionhq/next'

import { featureToggles } from '../configuration/feature-toggles'
import { INTL_DEFAULTS } from '../configuration/intl'

const { isMultilingualEnabled } = featureToggles

const { publicRuntimeConfig } = getConfig()
const { apiUrl } = publicRuntimeConfig.bloomreach ?? {}

const bloomreachSettings: BrConfigurationContextOptions = {
  apiUrl,
}

const settings: Parameters<typeof withBloomreach>[0] = {
  bloomreach: bloomreachSettings,
  isMultilingualEnabled,
  defaultLocale: INTL_DEFAULTS.locale,
}

export default withBloomreach(settings)
